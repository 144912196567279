import styled from 'styled-components';

import { getCssFromTheme } from '../../../utils/css';
import Label from '../Label';
import { IStyledProps } from '../../../types';

export const StyledWrapper = styled.div<IStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth, width }) => width || (fullWidth && '100%')};
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.color.general.light};
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  & ~ .link {
    border-radius: 7.5px;
  }

  &.withoutBorder {
    border: 1px solid transparent;
  }

  &.withoutValidation {
    margin-bottom: 0;
  }

  &.withInsideButtons:not(.searchInput) {
    margin-bottom: 10px;
    padding-bottom: 60px;
  }

  &.hasError {
    margin-bottom: 0;
    border: 1px solid ${({ theme }) => theme.color.status.error};
  }

  &.hasError ~ .link .label {
    color: ${({ theme }) => theme.color.status.error};
  }

  &.success {
    border: 1px solid ${({ theme }) => theme.color.status.success};
  }

  &.disabled {
    border-color: ${({ theme }) => theme.color.general.lightest};
    background-color: ${({ theme }) => theme.color.general.lightest};
    cursor: not-allowed;
  }

  .rightIcon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
  }

  & > .icon.leftIcon {
    position: absolute;
    top: 14px;
    left: 15px;
  }

  input {
    width: inherit;
    border-radius: 10px;
  }
  
  textarea {
    border-radius: 10px;
  }

  .replyContainer {
    display: flex;
    justify-content: space-between;
    padding: 9px 12px;
    overflow: hidden;
    border-radius: 3px;
    font-size: 12px;
    line-height: 16px;

    .replyName {
      font-size: 14px;
      font-weight: 600;
      line-height: 23px;
    }

    .replyContent {
      position: relative;
      width: 100%;
      margin: 0 15px;
      padding: 4px 4px 4px 8px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.color.general.lightest};

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        content: '';
      }

      &.file {
        display: flex;
        align-items: center;

        img {
          width: 26px;
          height: 26px;
          margin: 0 8px 0 0;
          border-radius: 3px;
          object-fit: cover;
        }

        .iconType {
          width: 21px;
          height: 26px;
          margin: 0 8px 0 0;
        }
      }
    }

    .replyMsg,
    .replyName,
    .fileName {
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.contact-msg {

      .replyContent {

        .replyName {
          color: ${({ theme }) => theme.color.secondary.darker};
        }

        &::after {
          background-color: ${({ theme }) => theme.color.secondary.darker};
        }
      }
    }

    &.operator-msg {

      .replyContent {

        .replyName {
          color: ${({ theme }) => theme.color.general.orange};
        }

        &::after {
          background-color: ${({ theme }) => theme.color.general.orange};
        }
      }
    }
  }

  &.selectWrapper:not(.languageSelect).focused {
    border: 1px solid ${({ theme }) => theme.color.secondary.dark};
    outline: 2px solid ${({ theme }) => theme.color.secondary.lighter};

    .label.border {
      color: ${({ theme }) => theme.color.secondary.dark} !important;
    }

    .icon:not(.statusIcon, .chevronDown, .withoutHover) path {
      stroke: ${({ theme }) => theme.color.secondary.dark};
    }
  }

  &:focus-within:not(.hasError) {

    &.inputWrapper {
      border: 1px solid ${({ theme }) => theme.color.secondary.dark};
      outline: 2px solid ${({ theme }) => theme.color.secondary.lighter};
    }

    &:not(.selectWrapper, .languageSelect) .icon:not(.statusIcon, .chevronDown, .withoutHover, .replyIcon, .closeIcon, .iconType) path {
      stroke: ${({ theme }) => theme.color.secondary.dark};
    }

    &:not(.selectWrapper) .label.border {
      color: ${({ theme }) => theme.color.secondary.dark} !important;
    }

    ~ .link .label.border {
      color: ${({ theme }) => theme.color.secondary.dark} !important;
    }

    .react-select__single-value .row {
      color: ${({ theme }) => theme.color.secondary.dark};
    }

    &.hasError {
      outline: 2px solid ${({ theme }) => theme.color.status.error}40;
    }
  }
`;

export const StyledInput = styled.input<IStyledProps>`
  ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};

  flex-grow: 1;
  min-height: 42px;
  padding: 8px 15px;
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.color.general.black};
  resize: none;

  &.multiline {
    line-height: 22px;
  }

  &.hasLeftIcon {
    padding-left: 39px;
  }

  &.hasRightIcon {
    padding-right: 47px;
  }

  &.hasError {
    padding-right: 39px;
  }

  .disabled & {
    background-color: ${({ theme }) => theme.color.general.lightest};
    color: ${({ theme }) => theme.color.general.light};
    cursor: not-allowed;
  }

  ::placeholder {
    opacity: 1;
    color: ${({ theme, placeholderColor }) => placeholderColor || theme.color.general.dark};
  }
`;

export const StyledButtonsRow = styled.div`
  position: absolute;
  bottom: 10px;
  left: 15px;
  display: flex;

  & > button {
    width: 26px;
    height: 26px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    background-color: ${({ theme }) => theme.color.general.lightest};

    &:hover:not(.disabled) {
      background-color: ${({ theme }) => theme.color.general.lighter} !important;
    }
  }
`;

export const StyledLabel = styled(Label)`
  display: block;

  &.border {
    position: absolute;
    top: -8px;
    left: 9px;
    z-index: 2;
    padding: 0 7px;
    border-radius: 7.5px;
    background-color: ${({ theme }) => theme.color.general.white};

    .hasError & {
      color: ${({ theme }) => theme.color.status.error};
    }

    .disabled & {
      color: ${({ theme }) => theme.color.general.light};
    }
  }

  &.top {
    margin-bottom: 5px;
  }

  &.required::after {
    content: ' *';
    color: ${({ theme }) => theme.color.status.error};
  }
`;

export const StyledErrorLabel = styled(Label)`
  padding: 10px 0 12px 0;
  color: ${({ theme }) => theme.color.status.error};

  &:last-child {
    padding-bottom: 0;
  }

  .icon {
    margin-right: 5px;
  }
`;