import React from 'react';

import MessageItem from './MessageItem';
import ChatActionDivider from './ChatActionDivider';
import Rating from './Rating';
import IsTypingIndicator from './atomic/IsTypingIndicator';
import { ChatEvent, IChatEventBrief, IFileUploadResult } from '../types';

interface IProps {
	data: IChatEventBrief,
	operatorOnline: boolean,
	onScrollReplyMsg: (id: number) => void,
	onClickReply: (
		id: number,
		replyMsg: string,
		type: ChatEvent,
		file: IFileUploadResult | null,
		owner?: string
	) => void,
}

const ChatAction = (props: IProps) => {
	const {
		data, operatorOnline, onScrollReplyMsg, onClickReply,
	} = props;
	if (data?.type === ChatEvent.operator_typing) {
		return <IsTypingIndicator />;
	}

	const allowedTypesForMessageItem: ChatEvent[] = [
		ChatEvent.contact_message,
		ChatEvent.operator_message,
		ChatEvent.system_message,
	];

	if (data?.type && allowedTypesForMessageItem.includes(data.type)) {
		return (
			<MessageItem
				action={data}
				operatorOnline={operatorOnline}
				isSystemMessage={data.type === ChatEvent.system_message}
				onScrollReplyMsg={onScrollReplyMsg}
				onClickReply={onClickReply}
			/>
		);
	}

	if (data?.type === ChatEvent.show_rating_options) {
		return (
			<Rating />
		);
	}

	return (
		<ChatActionDivider
			action={data}
		/>
	);
};

export default ChatAction;