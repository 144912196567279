import { ChatEvent } from './types';

export const FILE_TYPES = {
	images: {
		'image/jpeg': ['.jpg', '.jpeg'],
		'image/png': ['.png'],
	},
	files: {
		'application/pdf': ['.pdf'],
		'text/plain': ['.txt'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
		'application/vnd.ms-powerpoint': ['.ppt'],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
		'image/heic': ['.heic'],
	},
};

export const CHAT_EVENTS_TO_SHOW_IN_WIDGET: ChatEvent[] = [
	ChatEvent.operator_typing,
	ChatEvent.contact_message,
	ChatEvent.operator_message,
	ChatEvent.system_message,
	ChatEvent.operator_joined,
	ChatEvent.forwarded_operator,
	ChatEvent.forwarded_department,
	ChatEvent.chat_closed,
	ChatEvent.operator_rated,
	ChatEvent.chat_reopened,
];

export const BOTS_URLS_START: Record<string, string> = {
	telegram: 'https://t.me/',
	viber: 'viber://pa?chatURI=',
	whatsapp: 'https://wa.me/',
	instagram: 'https://ig.me/m/',
	facebook: 'https://m.me/',
};

export const REG_EXP_PHONE = /^(?=.*\d)[- +()_0-9]+$/g;
export const MAX_PHONE_LENGTH = 20;
export const MIN_PHONE_LENGTH = 9;

export const FILE_SIZE_LIMIT = 20971520;

export const CHAT_EVENTS_LOAD_LIMIT = 40;

export const HEADER_HEIGHT = 56;
export const CHOOSE_CHANNELS_HEADER_HEIGHT = 90;
export const CHOOSE_CHANNELS_HEADER_HEIGHT_UK = 105;
export const CHOOSE_CHANNELS_FOOTER_HEIGHT = 44;
export const CONTACT_FORM_FOOTER_HEIGHT = 33;
export const CHANNEL_BUTTON_HEIGHT = 54;
export const PAGINATION_TABS_HEIGHT = 41;

export const INPUT_HEIGHT = 44 + 23;
export const CHECKBOX_HEIGHT = 90;
export const CHECKBOX_HEIGHT_EN = 74;
export const TEXTAREA_HEIGHT = 140;
export const HEADER_HEIGHT_PRE_CHAT_FORM = 115;
export const HEADER_HEIGHT_PRE_CHAT_FORM_EN = 100;
export const HEADER_WITH_ARROW_HEIGHT_PRE_CHAT_FORM = 100;
export const CHOOSE_CHANNELS_HEADER_WITH_OFFLINE_HEIGHT = 170;
export const CONTACT_FORM_HEADER_WITH_OFFLINE_HEIGHT = 180;
export const LIVE_CHAT_HEIGHT = 550;
export const LIVE_CHAT_HEADER_WITH_OFFLINE = 162;
export const LIVE_CHAT_HEADER_WITHOUT_OFFLINE = 67;
export const KNOWLEDGE_BASE_LIST_HEIGHT = 617;
export const KNOWLEDGE_BASE_LIST_LARGE_HEIGHT = 675;
export const CALLBACK_FORM_HEIGHT = 238;
export const CALLBACK_FORM_HEIGHT_EN = 240;
export const CALLBACK_FORM_HEIGHT_WITH_OFFLINE_TEXT = 65;
export const MAIN_BUTTON_SIZE = 59;
export const MAIN_BUTTON_GAP = 20;
export const BOTH_BUTTONS_CONTAINER_SIZE = 118 + 20;

export const CUSTOM_EVENT_SEND_MESSAGE = 'skibble-send-message';
export const CUSTOM_EVENT_SEND_SYSTEM_MESSAGE = 'skibble-send-system-message';

export enum EnumLocalStorageKeys {
	systemMessages = 'systemMessages',
}