import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const reply = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M15.4286 14.2853C12.088 11.459 11.2663 10.2853 8.00001 10.2853H6.28573V13.7139L0.571442 7.42815L6.28573 1.71387V5.14244H7.42858C13.1429 5.14244 14.2857 10.8567 15.4286 14.2853Z"
				stroke={fill || theme.color.general.dark}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};