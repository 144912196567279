import React, { useEffect, useState } from 'react';

import RatingWrite from './atomic/RatingWrite';
import { useSendEventWebsocket } from '../hooks/useWidgetWebsocket';
import { useLiveChat } from '../LiveChatProvider';
import { ChatEvent } from '../types';

const Rating = () => {
	const [rating, setRating] = useState<number | null>(null);

	const {
		setHistoryEvent, operatorName, chatStarted, setChatStarted, setHistory,
	} = useLiveChat();

	const onSendRating = () => {
		if (rating !== null) {
			// eslint-disable-next-line no-use-before-define
			sendEventToServer({ type: 'operator_rated', data: { rating, operator_name: operatorName } });
		}
	};

	const onRateSuccess = (eventType: string, callback: () => void) => {
		if (rating !== null && eventType === 'operator_rated') {
			setChatStarted(false);
			setHistoryEvent({
				type: 'operator_rated' as ChatEvent,
				// @ts-ignore
				data: { rating, operator_name: operatorName },
			});
			setRating(null);
			setHistory((prev) => prev.filter((item) => item.type !== 'show_rating_options'));
			callback();
		}
	};

	const {
		sendEventToServer,
		eventInProgress,
		setEventSentStatus,
		setEventInProgress,
		onSendEvent,
	} = useSendEventWebsocket(onSendRating, onRateSuccess);

	useEffect(() => {
		if (rating !== null && eventInProgress === 'operator_rated' && chatStarted) {
			onSendEvent('operator_rated')();
		}
	}, [chatStarted, eventInProgress, rating]);

	const onStarClick = (rating: number) => {
		setRating(rating);
		setChatStarted(true);
		setEventInProgress('operator_rated');
		setEventSentStatus('inProgress');
	};

	return <RatingWrite onStarClick={onStarClick} />;
};

export default Rating;