import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const typePPT = ({ width, height }: IPropsSvgIcon) => (
	<svg width={width || 30} height={height || 36} viewBox="0 0 22 26" fill="none">
		<path d="M0 1.33333C0 0.596954 0.596954 0 1.33333 0H13L21.6667 8.66667V24.6667C21.6667 25.403 21.0697 26 20.3333 26H1.33333C0.596954 26 0 25.403 0 24.6667V1.33333Z" fill="#E65400" />
		<path d="M21.6667 8.66667H15.1667C13.97 8.66667 13 7.69662 13 6.5V0L21.6667 8.66667Z" fill="#CF1C00" />
		<path d="M4.86475 20.0417V15.3145H6.7298C7.08835 15.3145 7.39381 15.3829 7.64617 15.5199C7.89854 15.6553 8.09089 15.8438 8.22323 16.0854C8.35711 16.3255 8.42405 16.6025 8.42405 16.9164C8.42405 17.2303 8.35634 17.5073 8.22092 17.7473C8.08551 17.9874 7.88931 18.1744 7.63232 18.3082C7.37688 18.4421 7.06758 18.5091 6.70441 18.5091H5.51567V17.7081H6.54284C6.73519 17.7081 6.89369 17.675 7.01833 17.6088C7.14452 17.5411 7.23838 17.448 7.29994 17.3295C7.36303 17.2095 7.39458 17.0718 7.39458 16.9164C7.39458 16.7594 7.36303 16.6225 7.29994 16.5055C7.23838 16.387 7.14452 16.2955 7.01833 16.2308C6.89215 16.1647 6.73211 16.1316 6.53822 16.1316H5.86421V20.0417H4.86475Z" fill="white" />
		<path d="M9.07324 20.0417V15.3145H10.9383C11.2968 15.3145 11.6023 15.3829 11.8547 15.5199C12.107 15.6553 12.2994 15.8438 12.4317 16.0854C12.5656 16.3255 12.6325 16.6025 12.6325 16.9164C12.6325 17.2303 12.5648 17.5073 12.4294 17.7473C12.294 17.9874 12.0978 18.1744 11.8408 18.3082C11.5854 18.4421 11.2761 18.5091 10.9129 18.5091H9.72417V17.7081H10.7513C10.9437 17.7081 11.1022 17.675 11.2268 17.6088C11.353 17.5411 11.4469 17.448 11.5084 17.3295C11.5715 17.2095 11.6031 17.0718 11.6031 16.9164C11.6031 16.7594 11.5715 16.6225 11.5084 16.5055C11.4469 16.387 11.353 16.2955 11.2268 16.2308C11.1006 16.1647 10.9406 16.1316 10.7467 16.1316H10.0727V20.0417H9.07324Z" fill="white" />
		<path d="M13.1017 16.1385V15.3145H16.9842V16.1385H15.5369V20.0417H14.549V16.1385H13.1017Z" fill="white" />
	</svg>
);