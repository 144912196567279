import React, { Dispatch, SetStateAction } from 'react';

import { StyledScreenWrapper } from '../WidgetButtonContainer/styled';
import WidgetStartHeader from '../ChooseChannels/components/WidgetStartHeader';
import WidgetFooter from '../ChooseChannels/components/WidgetFooter';
import PreChatFormFields from './components/PreChatFormFields';
import { IResponseChatWidgetLicense, ScreenType } from '../../types';
import PaginationTabs from '../../components/PaginationTabs';

interface IProps {
	screens: ScreenType[],
	currentScreen: ScreenType,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>
	className: string,
	hideWidget: () => void,
	goOneScreenForward: () => void,
	goOneScreenBack?: () => void,
	is_hide_branding: boolean,
	offlineText: string | false,
	fields: IResponseChatWidgetLicense['settings']['form_fields'],
	privacy_policy: IResponseChatWidgetLicense['settings']['privacy_policy'],
}

const PreChatForm = (props: IProps) => {
	const {
		screens,
		currentScreen,
		setCurrentScreen,
		className,
		hideWidget,
		goOneScreenForward,
		goOneScreenBack,
		is_hide_branding,
		offlineText,
		fields,
		privacy_policy,
	} = props;

	return (
		<StyledScreenWrapper className={className}>
			<WidgetStartHeader
				hideWorkingTime={screens[0] !== currentScreen}
				hideWidget={hideWidget}
				goOneScreenBack={goOneScreenBack}
				subtextKey={offlineText || 'sendMessageToStart'}
				currentScreen="preChatForm"
			/>

			<PreChatFormFields
				fields={fields}
				privacy_policy={privacy_policy}
				goOneScreenForward={goOneScreenForward}
			/>
			<PaginationTabs
				screens={screens}
				currentScreen={currentScreen}
				setCurrentScreen={setCurrentScreen}
			/>
			{!is_hide_branding && <WidgetFooter />}
		</StyledScreenWrapper>
	);
};

export default PreChatForm;