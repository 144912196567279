import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const typeDOCX = ({ width, height }: IPropsSvgIcon) => (
	<svg width={width || 30} height={height || 36} viewBox="0 0 22 26" fill="none">
		<path d="M0 1.33333C0 0.596954 0.596954 0 1.33333 0H13L21.6667 8.66667V24.6667C21.6667 25.403 21.0697 26 20.3333 26H1.33333C0.596954 26 0 25.403 0 24.6667V1.33333Z" fill="#0D81FF" />
		<path d="M21.6667 8.66667H15.1667C13.97 8.66667 13 7.69662 13 6.5V0L21.6667 8.66667Z" fill="#0141FF" />
		<path d="M5.56934 20.0419H3.89355V15.3146H5.58319C6.05868 15.3146 6.46801 15.4093 6.81117 15.5985C7.15433 15.7863 7.41824 16.0563 7.60289 16.4087C7.78909 16.7611 7.88219 17.1828 7.88219 17.6737C7.88219 18.1661 7.78909 18.5893 7.60289 18.9432C7.41824 19.2971 7.15279 19.5687 6.80655 19.758C6.46185 19.9473 6.04945 20.0419 5.56934 20.0419ZM4.89302 19.1855H5.52779C5.82324 19.1855 6.07176 19.1332 6.27335 19.0286C6.47647 18.9224 6.62882 18.7585 6.73038 18.5369C6.83348 18.3138 6.88503 18.026 6.88503 17.6737C6.88503 17.3243 6.83348 17.0389 6.73038 16.8173C6.62882 16.5957 6.47724 16.4326 6.27566 16.3279C6.07407 16.2233 5.82555 16.171 5.5301 16.171H4.89302V19.1855Z" fill="white" />
		<path d="M12.9667 17.6783C12.9667 18.1938 12.8689 18.6323 12.6735 18.994C12.4796 19.3556 12.2149 19.6318 11.8795 19.8226C11.5456 20.0119 11.1701 20.1065 10.7531 20.1065C10.333 20.1065 9.95595 20.0111 9.62203 19.8203C9.2881 19.6295 9.02419 19.3533 8.8303 18.9917C8.63641 18.63 8.53946 18.1922 8.53946 17.6783C8.53946 17.1628 8.63641 16.7242 8.8303 16.3626C9.02419 16.0009 9.2881 15.7255 9.62203 15.5362C9.95595 15.3454 10.333 15.25 10.7531 15.25C11.1701 15.25 11.5456 15.3454 11.8795 15.5362C12.2149 15.7255 12.4796 16.0009 12.6735 16.3626C12.8689 16.7242 12.9667 17.1628 12.9667 17.6783ZM11.9533 17.6783C11.9533 17.3443 11.9033 17.0627 11.8033 16.8335C11.7048 16.6042 11.5656 16.4303 11.3855 16.3118C11.2055 16.1933 10.9947 16.1341 10.7531 16.1341C10.5115 16.1341 10.3006 16.1933 10.1206 16.3118C9.94056 16.4303 9.80053 16.6042 9.70051 16.8335C9.60202 17.0627 9.55278 17.3443 9.55278 17.6783C9.55278 18.0122 9.60202 18.2938 9.70051 18.5231C9.80053 18.7524 9.94056 18.9263 10.1206 19.0447C10.3006 19.1632 10.5115 19.2225 10.7531 19.2225C10.9947 19.2225 11.2055 19.1632 11.3855 19.0447C11.5656 18.9263 11.7048 18.7524 11.8033 18.5231C11.9033 18.2938 11.9533 18.0122 11.9533 17.6783Z" fill="white" />
		<path d="M17.878 16.9696H16.867C16.8485 16.8388 16.8108 16.7227 16.7539 16.6211C16.697 16.518 16.6239 16.4303 16.5346 16.358C16.4454 16.2856 16.3423 16.2302 16.2253 16.1918C16.1099 16.1533 15.9845 16.1341 15.8491 16.1341C15.6044 16.1341 15.3913 16.1948 15.2097 16.3164C15.0281 16.4364 14.8873 16.6119 14.7873 16.8427C14.6873 17.072 14.6372 17.3505 14.6372 17.6783C14.6372 18.0153 14.6873 18.2984 14.7873 18.5277C14.8888 18.757 15.0304 18.9301 15.212 19.0471C15.3936 19.164 15.6036 19.2225 15.8422 19.2225C15.976 19.2225 16.0999 19.2048 16.2138 19.1694C16.3292 19.134 16.4315 19.0824 16.5208 19.0147C16.61 18.9455 16.6839 18.8616 16.7424 18.7631C16.8024 18.6647 16.8439 18.5523 16.867 18.4261L17.878 18.4308C17.8519 18.6477 17.7865 18.857 17.6818 19.0586C17.5787 19.2586 17.4395 19.4379 17.264 19.5964C17.0901 19.7534 16.8824 19.878 16.6408 19.9703C16.4007 20.0611 16.1291 20.1065 15.826 20.1065C15.4044 20.1065 15.0273 20.0111 14.695 19.8203C14.3641 19.6295 14.1025 19.3533 13.9102 18.9917C13.7193 18.63 13.6239 18.1922 13.6239 17.6783C13.6239 17.1628 13.7209 16.7242 13.9148 16.3626C14.1087 16.0009 14.3718 15.7255 14.7042 15.5362C15.0366 15.3454 15.4105 15.25 15.826 15.25C16.0999 15.25 16.3538 15.2885 16.5877 15.3654C16.8232 15.4424 17.0317 15.5547 17.2132 15.7024C17.3948 15.8486 17.5426 16.0279 17.6564 16.2402C17.7718 16.4526 17.8457 16.6957 17.878 16.9696Z" fill="white" />
	</svg>
);