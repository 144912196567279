import { MessageStatus } from '../types';

export const constructMessageData = (message: {
  text: string,
  delay?: number,
  status: MessageStatus
}) => ({
	type: 'system_message',
	data: {
		text: message.text,
	},
});

export const today = new Date().toISOString().slice(0, 10);

export const dateMessageSended = (messageType: string, chatStartedOrigin: string) => localStorage.getItem(`${messageType}-${chatStartedOrigin}`);

export const isMessageSendedToday = (
	messageType: string,
	chatStartedOrigin: string,
) => today === dateMessageSended(messageType, chatStartedOrigin);