import { SYSTEM_MESSAGES } from '../enums';
import { IChatEventBrief, MessageStatus } from '../types';
import {
	constructMessageData, isMessageSendedToday, today,
} from './helpers';

interface Params {
	message: {
    text: string,
    delay: number,
    status: MessageStatus
	},
	chatStarted: boolean,
	setHistory: React.Dispatch<React.SetStateAction<IChatEventBrief[]>>,
	setSystemMessages: React.Dispatch<React.SetStateAction<IChatEventBrief[] | []>>,
	setTimeoutId: React.Dispatch<React.SetStateAction<null | NodeJS.Timeout>>,
	chatStartedOrigin: string,
}

export const handleSecondMessage = (props: Params) => {
	const {
		message,
		chatStarted,
		setHistory,
		setSystemMessages,
		setTimeoutId,
		chatStartedOrigin,
	} = props;

	const DELAY_SENDED_MSG = 0;

	const secondMessage = constructMessageData(message) as IChatEventBrief;

	const secondMessageWithDelayKey = `${SYSTEM_MESSAGES['live_chat_delayed.second_message']}-${chatStartedOrigin}`;
	const isSendedToday = isMessageSendedToday(SYSTEM_MESSAGES['live_chat_delayed.second_message'], chatStartedOrigin);

	const showMessage = (delay: number) => {
		localStorage.setItem(secondMessageWithDelayKey, today);
		const timeoutId = setTimeout(() => {
			setHistory((prev: IChatEventBrief[]) => [...prev, secondMessage]);
			setSystemMessages((prev) => [...prev, secondMessage]);
		}, delay);
		setTimeoutId(timeoutId);
	};

	if (!chatStarted) {
		const delay = !isSendedToday ? message.delay * 1000 : DELAY_SENDED_MSG;
		showMessage(delay);
	}

	if (chatStarted && !isSendedToday) {
		showMessage(message.delay * 1000);
	}
};