import React from 'react';

import { StyledButtonWrapper } from '../../components/styled';
import Icon from '../../components/atomic/Icon/Icon';
import { useWidget } from '../../WidgetProvider';
import { useLiveChat } from '../../LiveChatProvider';
import { NameTypeIcon } from '../../types';
import { systemMessagesHandler } from '../../systemMessages/systemMessagesHandler';

interface IProps {
	className: string,
}

const WidgetButton = (props: IProps) => {
	const { className } = props;
	const {
		widgetOpened, setWidgetOpened, widgetSettings, isNonWorkingTime,
	} = useWidget();
	const { isAllMessagesSeen } = useLiveChat();

	const isLiveChatActive = widgetSettings?.channels?.live_chat?.status === 'active';

	const button_placement = widgetSettings?.settings?.button_placement;

	const { button_style } = widgetSettings.settings || {};
	const buttonName = button_style === 'briefcase' ? 'typeBriefcase' : button_style || 'typeSkibble';

	const iconName = isLiveChatActive && !widgetOpened && !isAllMessagesSeen
		? `${buttonName}WithNotifications`
		: buttonName;

	systemMessagesHandler({
		autoReplySettings: widgetSettings.auto_reply_settings,
		isNonWorkingTime,
	});

	return (
		<StyledButtonWrapper placement={button_placement}>
			<Icon
				className={className}
				name={iconName as NameTypeIcon}
				onClick={() => setWidgetOpened(!widgetOpened)}
				width={isLiveChatActive && !widgetOpened && !isAllMessagesSeen ? 59 : 56}
				height={isLiveChatActive && !widgetOpened && !isAllMessagesSeen ? 59 : 56}
				alignItems="end"
				justifyContent="start"
			/>
		</StyledButtonWrapper>
	);
};

export default WidgetButton;