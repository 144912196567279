import React, { useState, useRef, useContext } from 'react';
import isEmail from 'sane-email-validation';
import clsx from 'clsx';

import styled from 'styled-components';
import Input from '../../../components/atomic/Input';
import { IProps as IPropsIcon } from '../../../components/atomic/Icon/Icon';
import { StyledChatsContainer } from '../../LiveChat/styled';
import Textarea from '../../../components/Textarea';
import Checkbox from '../../../components/atomic/Checkbox/Checkbox';
import { useWidget } from '../../../WidgetProvider';
import { useLiveChat } from '../../../LiveChatProvider';
import { isObjectEmpty } from '../../../utils';
import { IResponseChatWidgetLicense } from '../../../types';
import { LangContext } from '../../../LangProvider';
import { MAX_PHONE_LENGTH, MIN_PHONE_LENGTH, REG_EXP_PHONE } from '../../../constants';

const PrivacyPolicyWrap = styled.div<{ additionalMargin: boolean }>`
  margin-bottom: ${({ additionalMargin }) => (additionalMargin ? '20px' : '0')};
`;

interface IProps {
	fields: IResponseChatWidgetLicense['settings']['form_fields'],
	goOneScreenForward: () => void,
	privacy_policy: IResponseChatWidgetLicense['settings']['privacy_policy'],
}

const CONFIG: Record<string, { key: string, label: string, iconLeftProps: IPropsIcon }> = {
	name: { key: 'name', label: 'name', iconLeftProps: { name: 'contactCard' } },
	phone: { key: 'phone', label: 'phone', iconLeftProps: { name: 'phone' } },
	email: { label: 'email', key: 'email', iconLeftProps: { name: 'doggySign' } },
};

type FieldsKeys = (keyof IResponseChatWidgetLicense['settings']['form_fields']);

type StateType = Record<FieldsKeys, undefined | boolean | string>;

const PreChatFormFields = (props: IProps) => {
	const {
		fields,
		goOneScreenForward,
		privacy_policy,
	} = props;

	const { chatDetails, widgetSettings } = useWidget();
	const { preChatFormValues } = useLiveChat();
	const getTranslate = useContext(LangContext);

	const { is_hide_branding } = widgetSettings?.settings || {};

	const fieldsVisible = Object.keys(fields).filter((key) => fields[key as FieldsKeys].is_visible);

	const initialValue = fieldsVisible
		.reduce((initialObj, field) => ({ ...initialObj, [field]: '' }), {});

	// @ts-ignore
	const [inputValues, setInputValues] = useState<StateType>(initialValue);

	// @ts-ignore
	const [errors, setErrors] = useState<StateType>({});

	const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

	const isSubmitPressed = useRef(false);

	const messageText = chatDetails?.text;

	const getIsFormValidElementRequired = ({
		field,
		formErrors,
		valueToCheck,
	}: {
		field: FieldsKeys,
		formErrors: StateType,
		valueToCheck: string,
	}) => {
		if (field === 'email') {
			formErrors[field] = isEmail(valueToCheck) ? '' : 'invalidEmail';
		}

		if (field === 'phone') {
			const isPhoneNumberValid = valueToCheck.match(REG_EXP_PHONE)
			&& valueToCheck.length >= MIN_PHONE_LENGTH && valueToCheck.length <= MAX_PHONE_LENGTH;
			formErrors[field] = isPhoneNumberValid ? '' : 'invalidPhone';
		}

		// @ts-ignore
		if (!fields[field].is_required && !valueToCheck) {
			formErrors[field] = '';
		}
	};

	const getIsFormValidElement = ({
		fieldToCheck,
		value,
		field,
		formErrors,
	}: {
		fieldToCheck: FieldsKeys,
		value?: string,
		field: FieldsKeys,
		formErrors: StateType,
	}) => {
		const valueToCheck = fieldToCheck ? value : inputValues[field];

		if (fieldToCheck && fieldToCheck !== field) {
			return undefined;
		}

		// @ts-ignore
		if (fields[field].is_required) {
			formErrors[field] = valueToCheck ? '' : 'required';
		}

		if (formErrors[field] !== 'required') {
			getIsFormValidElementRequired({
				field,
				formErrors,
				valueToCheck: typeof valueToCheck === 'string' ? valueToCheck : '',
			});
		}

		return undefined;
	};

	const getIsFormValid = (fieldToCheck?: string, value?: string) => {
		if (!fieldToCheck) {
			isSubmitPressed.current = true;
		}

		const formErrors: StateType = { ...errors };

		fieldsVisible.forEach((field) => {
			getIsFormValidElement({
				fieldToCheck: fieldToCheck as FieldsKeys,
				value,
				field: field as FieldsKeys,
				formErrors,
			});
		});

		// @ts-ignore
		if (privacy_policy.is_required && (fieldToCheck === 'privacy_policy' || !fieldToCheck)) {
			const checkboxValue = fieldToCheck ? value : isPrivacyPolicyChecked;
			// @ts-ignore
			formErrors.privacy_policy = checkboxValue ? '' : 'required';
		}

		setErrors(formErrors);

		const isFormValid = Object.values(formErrors).every((error) => !error);

		if (isFormValid) {
			// @ts-ignore
			const formValues: StateType = {};

			if (!isObjectEmpty(inputValues)) {
				// @ts-ignore
				formValues.contact = { ...inputValues };
			}

			// @ts-ignore
			preChatFormValues.current = { ...formValues };
		}

		return isFormValid;
	};

	const onInputChange = (e: { target: { name: string, value: string }}) => {
		const { name, value } = e.target;
		if (isSubmitPressed.current) {
			getIsFormValid(name as FieldsKeys, value);
		}
		setInputValues((prevState) => ({ ...prevState, [name]: value }));
	};

	const onCheckboxChange = (checked: boolean) => {
		if (isSubmitPressed.current) {
			// @ts-ignore
			getIsFormValid('privacy_policy', checked);
		}
		setIsPrivacyPolicyChecked(checked);
	};

	const getLink = () => {
		const values = privacy_policy.document_url.match(/(http(s)?:\/\/)?(.*)/);

		if (values && values[1] === undefined) {
			return `https://${values[3]}`;
		}

		return privacy_policy.document_url;
	};

	const getFieldNameTranslate = (fieldName: FieldsKeys) => getTranslate(CONFIG[fieldName].label);
	const privacyPolicyLinkTextTranslate = getTranslate('privacyPolicyLinkText');

	return (
		<>
			{fieldsVisible.map((fieldName) => {
				const value = inputValues[fieldName as FieldsKeys];

				return (
					<Input
						required={fields[fieldName as FieldsKeys].is_required}
						key={fieldName}
						iconLeftProps={CONFIG[fieldName as FieldsKeys].iconLeftProps}
						label={getFieldNameTranslate(fieldName as FieldsKeys)}
						error={!!errors[fieldName as FieldsKeys]}
						name={fieldName}
						value={typeof value === 'string' ? value : ''}
						onChange={onInputChange}
					/>
				);
			})}

			<StyledChatsContainer className="textarea">
				<Textarea
					currentScreen="preChatForm"
					getIsFormValid={getIsFormValid}
					messageText={messageText ?? ''}
					handleSendSuccess={goOneScreenForward}
				/>
			</StyledChatsContainer>

			{privacy_policy.is_required && (
				<PrivacyPolicyWrap additionalMargin={is_hide_branding}>
					<Checkbox
						withLabelChildren
						checked={isPrivacyPolicyChecked}
						name="privacy_policy"
						handleChange={onCheckboxChange}
						/* @ts-ignore */
						className={clsx(errors.privacy_policy && 'error')}
					>
						<span>
							{getTranslate('privacyPolicyAgreeText')}
							{' '}
						</span>
						{!privacy_policy.document_url && <span>{privacyPolicyLinkTextTranslate}</span>}
						{privacy_policy.document_url && (
							<a href={getLink()} target="_blank" rel="noreferrer noopener">
								{privacyPolicyLinkTextTranslate}
							</a>
						)}
					</Checkbox>
				</PrivacyPolicyWrap>
			)}
		</>
	);
};

export default PreChatFormFields;