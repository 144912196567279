import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const typeXLSX = ({ width, height }: IPropsSvgIcon) => (
	<svg width={width || 30} height={height || 36} viewBox="0 0 22 26" fill="none">
		<path d="M0 1.33333C0 0.596954 0.596954 0 1.33333 0H13L21.6667 8.66667V24.6667C21.6667 25.403 21.0697 26 20.3333 26H1.33333C0.596954 26 0 25.403 0 24.6667V1.33333Z" fill="#12B357" />
		<path d="M21.6667 8.66667H15.1667C13.97 8.66667 13 7.69662 13 6.5V0L21.6667 8.66667Z" fill="#017E1E" />
		<path d="M5.88443 15.3146L6.83774 16.9258H6.87467L7.83259 15.3146H8.96132L7.51867 17.6783L8.99363 20.0419H7.84413L6.87467 18.4284H6.83774L5.86828 20.0419H4.72339L6.20297 17.6783L4.75109 15.3146H5.88443Z" fill="white" />
		<path d="M9.59377 20.0419V15.3146H10.5932V19.2179H12.6199V20.0419H9.59377Z" fill="white" />
		<path d="M15.8462 16.6742C15.8277 16.488 15.7485 16.3433 15.6085 16.2402C15.4684 16.1371 15.2784 16.0856 15.0383 16.0856C14.8752 16.0856 14.7375 16.1087 14.6252 16.1548C14.5128 16.1995 14.4266 16.2618 14.3666 16.3418C14.3082 16.4218 14.2789 16.5126 14.2789 16.6142C14.2758 16.6988 14.2935 16.7727 14.332 16.8358C14.372 16.8989 14.4266 16.9535 14.4959 16.9996C14.5651 17.0443 14.6452 17.0835 14.736 17.1174C14.8267 17.1497 14.9237 17.1774 15.0268 17.2005L15.4515 17.302C15.6577 17.3482 15.847 17.4097 16.0193 17.4867C16.1917 17.5636 16.3409 17.6583 16.4671 17.7706C16.5933 17.8829 16.691 18.0153 16.7603 18.1676C16.8311 18.32 16.8672 18.4946 16.8688 18.6916C16.8672 18.9809 16.7934 19.2317 16.6472 19.4441C16.5025 19.6549 16.2932 19.8188 16.0193 19.9357C15.747 20.0511 15.4184 20.1088 15.0337 20.1088C14.6521 20.1088 14.3197 20.0504 14.0366 19.9334C13.7549 19.8165 13.5349 19.6433 13.3764 19.4141C13.2194 19.1832 13.1371 18.8978 13.1294 18.5577H14.0966C14.1073 18.7162 14.1527 18.8485 14.2328 18.9547C14.3143 19.0594 14.4228 19.1386 14.5582 19.1925C14.6952 19.2448 14.8498 19.271 15.0222 19.271C15.1914 19.271 15.3384 19.2463 15.463 19.1971C15.5892 19.1478 15.6869 19.0794 15.7562 18.9917C15.8254 18.9039 15.8601 18.8031 15.8601 18.6893C15.8601 18.5831 15.8285 18.4938 15.7654 18.4215C15.7039 18.3492 15.6131 18.2876 15.4931 18.2369C15.3746 18.1861 15.2291 18.1399 15.0568 18.0984L14.5421 17.9691C14.1435 17.8722 13.8288 17.7206 13.598 17.5144C13.3672 17.3082 13.2525 17.0304 13.2541 16.6811C13.2525 16.3949 13.3287 16.1448 13.4826 15.9309C13.638 15.717 13.8511 15.5501 14.122 15.43C14.3928 15.31 14.7006 15.25 15.0453 15.25C15.3961 15.25 15.7023 15.31 15.9639 15.43C16.2271 15.5501 16.4317 15.717 16.5779 15.9309C16.7241 16.1448 16.7995 16.3926 16.8041 16.6742H15.8462Z" fill="white" />
	</svg>
);