import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export interface IPropsSvgIcon {
	width?: string | number;
	height?: string | number;
	fill?: string;
	stroke?: string;
	wrapperColor?: string;
}

export interface IStyledProps {
	tooltipMaxWidth?: number,
	placement?: string,
	justifyContent?: string;
	alignItems?: string;
	src?: string;
	backgroundSize?: string;
	margin?: string | number;
	width?: string | number;
	maxWidth?: string | number;
	height?: string | number;
	size?: string | number;
	maxHeight?: string | number;
	radius?: string | number;
	borderRadius?: string;
	wrapperColor?: string;
	fullWidth?: boolean;
	placeholderColor?: string;
	$isUppercase?: boolean;
	$textDecoration?: string;
	sizeType?: 'small' | 'medium' | 'big';
	inverted?: boolean;
	fontWeight?: string | number;
	$textTransform?: string;
	top?: string;
	left?: string;
	withOverlay?: boolean;
	themeColor?: 'primary.main' | 'secondary.main' | 'general.black' | 'general.light' | 'general.dark' | 'general.white' | 'status.new';
	backgroundColor?: string;
	fontSize?: string;
	lineHeight?: number | string;
	outlineColor?: string;
	padding?: string;
	borderColor?: string;
	hexOpacity?: string;
	fontFamily?: string;
	variant?: string;
	typographyVariant?: string;
	textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	labelType?: 'h1' |'h2' | 'h3' | 'h4' | 'caption1' | 'caption2' | 'caption3' | 'body1' | 'body2' | 'body3' | 'button1' | 'input';
}

export type Lang = 'en' | 'uk' | 'ru';

export enum WidgetStatus {
	active = 'active',
	inactive = 'inactive',
}

export enum InstallStatus {
	installed = 'installed',
	not_installed = 'not_installed'
}

export enum ChannelStatus {
	active = 'active',
	inactive = 'inactive',
}

export enum WidgetPosition {
	left = 'left',
	right = 'right',
}

export enum MessageStatus {
	active = 'active',
	inactive = 'inactive',
}

interface DepartmentInfo {
	id:	number;
	name:	string;
}

export interface IChannel {
	status: ChannelStatus,
	page_name?: string;
	phone?: string;
	bot_url?: string;
	page_id?: string;
	bot_name?: string;
}

export interface IAutoReplySettings {
	live_chat_delayed: {
		first_message: {
			text: string,
			delay: number,
			status: MessageStatus,
		},
		second_message: {
			text: string,
			delay: number,
			status: MessageStatus,
		},
		idle_operator_open_chat: {
			text: string,
			delay: number,
			status: MessageStatus,
		},
		idle_operator_new_chat: {
			text: string,
			delay: number,
			status: MessageStatus,
		},
		idle_guest: {
			text: string,
			delay: number,
			status: MessageStatus,
		}
	},
	all_channels_instant: {
		first_message: {
			text: string,
			status: MessageStatus,
		},
		second_message: {
			text: string,
			status: MessageStatus,
		},
		non_working_hours: {
			text: string,
			status: MessageStatus,
		}
	}
}

export interface IResponseChatWidgetLicense {
	name: string,
	license_id: string,
	is_working_now: boolean;
	status: WidgetStatus,
	install_status: InstallStatus,
	channels: {
		live_chat: IChannel,
		callback?: IChannel,
		telegram: IChannel,
		viber: IChannel,
		facebook: IChannel,
		whatsapp: IChannel,
		instagram: IChannel
	},
	settings: {
		color: string,
		is_hide_branding: boolean,
		button_style: string,
		button_placement: WidgetPosition,
		callback_button_style: string,
		callback_button_placement: WidgetPosition,

		is_form_displayed: boolean,
		form_fields: {
			name: {
				is_visible: boolean,
				is_required: boolean,
			},
			email: {
				is_visible: boolean,
				is_required: boolean,
			},
			phone: {
				is_visible: boolean,
				is_required: boolean,
			}
		},
		departments: {
			is_selected: boolean,
			values: number[],
		},
		privacy_policy: {
			is_required: boolean,
			document_url: string,
		}
	},
	auto_reply_settings: IAutoReplySettings,
	departments: DepartmentInfo[],
	timezone_offset: string,
	timezone_region: string,
	work_hours: {
		monday: {
			is_open: boolean,
			start: string,
			close: string,
		},
		tuesday: {
			is_open: boolean,
			start: string,
			close: string,
		},
		wednesday: {
			is_open: boolean,
			start: string,
			close: string,
		},
		thursday: {
			is_open: boolean,
			start: string,
			close: string,
		},
		friday: {
			is_open: boolean,
			start: string,
			close: string,
		},
		saturday: {
			is_open: boolean,
			start: string,
			close: string,
		},
		sunday: {
			is_open: boolean,
			start: string,
			close: string,
		}
	},
	language: Lang
}

export enum ChatStatus {
	new = 'new',
	open = 'open',
	closed = 'closed',
	forwarded = 'forwarded'
}

export enum ChatEvent {
	chat_closed = 'chat_closed',
	contact_message = 'contact_message',
	operator_message = 'operator_message',
	system_message = 'system_message',
	bulk_message = 'bulk_message',
	callback_message = 'callback_message',
	operator_joined = 'operator_joined',
	chat_started = 'chat_started',
	forwarded_operator = 'forwarded_operator',
	forwarded_department = 'forwarded_department',
	operator_rated = 'operator_rated',
	contact_typing = 'contact_typing',
	contact_not_typing = 'contact_not_typing',
	operator_typing = 'operator_typing',
	operator_not_typing = 'operator_not_typing',
	show_rating_options = 'show_rating_options',
	get_channel_message_id = 'get_channel_message_id',
	chat_reopened = 'chat_reopened',
}

export interface IResponseChatContactUpload {
	file_name: string;
	file_size: number;
	file_code: string;
}

export interface IFileUploadResult extends IResponseChatContactUpload {
	type: string;
	url: string;
}

export interface IChatEventBrief {
	id?: number,
	chat_id?: number,
	chat_status?: ChatStatus,
	status?: ChatStatus,
	type?: ChatEvent,
	data: {
		type?: ChatEvent,
		operator_id?: number,
		operator_name?: string,
		channel_message_id?: number,
		data?: object,
		rating?: number,
		is_business_comment?: boolean;
		reply_message_id?: number,
		reply_message_text?: string,
		reply_message_file_data?: IFileUploadResult[],
		reply_message_type?: ChatEvent,
		closed_by?: string,
		from_operator_name?: string,
		to_operator_name?: string,
		to_department_name?: string,
		text?: string,
		files?: IFileUploadResult[]
		business_comment_id?: number,
		business_images?: string[],
	},
	at?: string | Date,
	operator_id?: number,
	operator_name?: string,
	operator_pic?: string,
	message_id?: number,
	channel_message_id?: number,
}

export interface IResponseChatContactEvents {
	items: IChatEventBrief[],
	total: number;
	limit: number;
	offset: number;
}

export interface IResponseChatFileUrl {
	url: string,
}

export interface IEvent {
	data: object,
}

export type IframeType = 'callback' | 'widget';

export interface ChatDetails {
	text?: string,
	form?: string,
	fields?: string[],
}

export interface IKnowledgeBaseArticle {
	id: number;
	title: string;
}

export interface IKnowledgeBaseFolder {
	id: number;
	name: string;
	articles: IKnowledgeBaseArticle[];
}

export interface IKnowledgeBaseArticleDetailsResponse extends IKnowledgeBaseArticle {
	context: string;
	user_page_link: string;
	article_interactions: boolean;
}

export interface IKnowledgeBaseResponse {
	folders: IKnowledgeBaseFolder[]
	articles: IKnowledgeBaseArticle[]
}

export interface IWidgetContext {
	license: string,
	contactUid: string,
	chatStartedOrigin: string,
	chatStartedUrl: string,
	chatDetails: ChatDetails | null,
	isLargeScreen: boolean,
	changeIframeWidth: (width: number) => void,
	setIsLargeScreen: Dispatch<SetStateAction<boolean>>,
	setChatDetails: Dispatch<SetStateAction<ChatDetails | null>>,
	setKnowledgeBaseItemId: Dispatch<SetStateAction<number | null>>,
	setWidgetOpened: (opened: boolean, iframeType?: IframeType) => void,
	widgetOpened: boolean,
	callbackOpened: boolean,
	changeLiveChatScreenHeight: (height: number, width: number) => void,
	changePreLiveChatScreenHeight: (height: number, width: number) => void,
	iframeType: IframeType,
	widgetSettings: IResponseChatWidgetLicense,
	isNonWorkingTime: boolean,
	isTwoButtonsOnOneSide: boolean,
	knowledgeBaseResponse: IKnowledgeBaseResponse,
	knowledgeBaseItemId: number | null,
	isPreChatFormFilled: boolean,
	setIsPreChatFormFilled: Dispatch<SetStateAction<boolean>>,
	knowledgeBaseArticleLink: string,
	setKnowledgeBaseArticleLink: Dispatch<SetStateAction<string>>,
	liveChatOpenedAfterFirstAutoReply: boolean,
	setLiveChatOpenedAfterFirstAutoReply: Dispatch<SetStateAction<boolean>>
}

export interface ILiveChatContext {
	history: IChatEventBrief[],
	totalHistoryCount: number,
	chatStarted: boolean,
	onSetIsAllMessagesSeen: (history: IChatEventBrief[], isAllSeen: boolean) => void,
	preChatFormValues: MutableRefObject<Record<string, string> | null>,
	isInstantAutoMessageSent: MutableRefObject<boolean>,
	operatorName: string,
	operatorOnline: boolean,
	chatStatus: string,
	currentHistoryCount: number,
	eventJustSent: string,
	eventJustReceived: string,
	chatId: number,
	isSoundOn: boolean,
	isAllMessagesSeen: boolean,
	setOperatorOnline: Dispatch<SetStateAction<boolean>>,
	setChatStarted: Dispatch<SetStateAction<boolean>>,
	setChatStatus: Dispatch<SetStateAction<string>>,
	setOperatorName: Dispatch<SetStateAction<string>>,
	setOperatorPic: Dispatch<SetStateAction<string>>,
	setCurrentHistoryCount: Dispatch<SetStateAction<number>>,
	setEventJustSent: Dispatch<SetStateAction<string>>,
	setEventJustReceived: Dispatch<SetStateAction<string>>,
	setReplyMessageId: Dispatch<SetStateAction<number | undefined>>,
	replyMessageId?: number,
	onToggleSounds: () => void,
	setHistory: Dispatch<SetStateAction<IChatEventBrief[]>>,
	setHistoryEvent: (event: IChatEventBrief) => void,
	setIsAllMessagesSeen: Dispatch<SetStateAction<boolean>>,
	systemMessages: IChatEventBrief[] | [],
	setSystemMessages: Dispatch<SetStateAction<IChatEventBrief[] | []>>,
	latestHistoryEventType?: string,
}

export type ScreenType = '' | 'liveChat' | 'preChatForm' | 'chooseChannels' | 'knowledgeBaseList' | 'knowledgeBaseItem' | 'callbackRequest';

export type MessageDataStatus = '' | 'operator_offline' | 'operator_online' | 'ok' | 'error' | 'contact_connected' | 'inProgress';

export type MessageDataType = ChatEvent.operator_typing | '';

export interface WebsocketMessageData {
	status?: MessageDataStatus;
	type?: MessageDataType;
	detail?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,sonarjs/redundant-type-aliases
export type TextAreaRef = any;

export interface ICallbackData {
	phone: string;
	origin: string;
	timezone: string;
	country: string;
	browser: string;
	os: string;
	device: string;
	browser_lang: string;
	cameFrom: string;
}

export type NameTypeIcon =
	| 'cross'
	| 'download'
	| 'mailSend'
	| 'inputError'
	| 'callBackIcon'
	| 'knowledgeBase'
	| 'disableSoundsHeader'
	| 'knowledgeBasePagination'
	| 'chatPaginationActive'
	| 'knowledgeBasePaginationActive'
	| 'chatPagination'
	| 'enableSoundsHeader'
	| 'starSign'
	| 'resizeOut'
	| 'resizeIn'
	| 'whatsappIcon'
	| 'search'
	| 'folder'
	| 'like'
	| 'disLike'
	| 'article'
	| 'articleLink'
	| 'statusActive'
	| 'paginationArrow'
	| 'skibbleIcon'
	| 'forwarded'
	| 'attachSign'
	| 'smile'
	| 'typeTXT'
	| 'typePDF'
	| 'typeDOCX'
	| 'typeDOC'
	| 'typeXLSX'
	| 'typeXLS'
	| 'typePPT'
	| 'typePPTX'
	| 'typeHEIC'
	| 'fileSign'
	| 'offline'
	| 'state'
	| 'chevronDownThin'
	| 'arrowLeft'
	| 'threeDotsHollow'
	| 'crossBubble'
	| 'typeSkibble'
	| 'typeQuestion'
	| 'typeBubble'
	| 'typeBubbles'
	| 'enableSounds'
	| 'disableSounds'
	| 'typeBriefcase'
	| 'typeSkibbleWithNotifications'
	| 'typeBriefcaseWithNotifications'
	| 'typeQuestionWithNotifications'
	| 'typeBubbleWithNotifications'
	| 'typeBubblesWithNotifications'
	| 'skibbleTextLogo'
	| 'chats'
	| 'facebookIcon'
	| 'instagramIcon'
	| 'viberIcon'
	| 'telegramIcon'
	| 'checkboxChecked'
	| 'checkboxUnchecked'
	| 'contactCard'
	| 'doggySign'
	| 'multiUsers'
	| 'phone'
	| 'bot'
	| 'typeCallback'
	| 'reply';