import React, { useContext } from 'react';
import styled from 'styled-components';
import { LangContext } from '../LangProvider';
import { useWidget } from '../WidgetProvider';
import { IResponseChatWidgetLicense } from '../types';

type DaysOfWeek = keyof IResponseChatWidgetLicense['work_hours']

const days: DaysOfWeek[] = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

const daysFromDate: DaysOfWeek[] = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
];

const Wrap = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.color.primary.main};
`;

const TitleWrap = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.color.general.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

const Time = styled(Title)`
  font-weight: 600;
`;

const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Day = styled.div<{ hoursOff: boolean, active: boolean }>`
  padding: 3px 8px 3px 8px;
  border: 1px solid ${({ theme }) => theme.color.general.white}50;
  border-radius: 5px;
  background-color: ${({ theme, active }) => (active ? `${theme.color.general.white}50` : theme.color.primary.main)};
`;

const Text = styled(Title)<{ dayOff: boolean }>`
  color: ${({ theme, dayOff }) => (dayOff ? `${theme.color.general.white}88` : theme.color.general.white)};
`;

const Schedule = () => {
	const getTranslate = useContext(LangContext);

	const { widgetSettings } = useWidget();

	if (widgetSettings.is_working_now) {
		return null;
	}

	const dateOfWeekNow = daysFromDate[(new Date()).getDay()];

	const currentDay = widgetSettings.work_hours[dateOfWeekNow];

	if (!currentDay) {
		return null;
	}

	return (
		<Wrap>
			{!currentDay.is_open ? (
				<TitleWrap>
					<Title>{getTranslate('dayOff')}</Title>
				</TitleWrap>
			) : (
				<TitleWrap>
					<Title>{getTranslate('workingHours')}</Title>
					<Time>{currentDay.start}</Time>
					<Title>{getTranslate('hourSeparator')}</Title>
					<Time>{currentDay.close}</Time>
				</TitleWrap>
			)}

			<ContentWrap>
				{days.map((day) => {
					const item = widgetSettings.work_hours[day];

					const hoursOff = day === dateOfWeekNow && item.is_open && !widgetSettings.is_working_now;

					if (!item) {
						return null;
					}

					return (
						<Day hoursOff={hoursOff} active={day === dateOfWeekNow} key={day}>
							<Text dayOff={!item.is_open}>
								{getTranslate(`day_${day}`)}
							</Text>
						</Day>
					);
				})}
			</ContentWrap>
		</Wrap>
	);
};

export default Schedule;