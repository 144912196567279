import styled from 'styled-components';
import { pick } from 'dot-object';

import { getCssFromTheme } from '../../../utils/css';
import { IStyledProps } from '../../../types';

export const StyledLink = styled.span<IStyledProps>`
  display: inline-flex;

  .icon {
    margin: 0 6px;
  }

  a {
    ${({ theme }) => getCssFromTheme(theme, 'link')};
    color: ${({ theme, themeColor, inverted }) => (inverted ? theme.color.general.white : pick(themeColor || '', theme.color))};
    font-weight: ${({ fontWeight }) => fontWeight};
    text-transform: ${({ $textTransform }) => $textTransform};
    text-decoration: none;
    cursor: pointer;
  }

  &.disabled a {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(.disabled) a {
    opacity: 0.7;
  }
`;