import React, { useContext } from 'react';

import styled from 'styled-components';
import { StyledWidgetStartHeader, StyledWidgetTitle } from '../styled';
import Icon from '../../../components/atomic/Icon/Icon';
import Typography from '../../../components/atomic/Typography';
import { ScreenType } from '../../../types';
import { LangContext } from '../../../LangProvider';
import Schedule from '../../../components/Schedule';
import { useWidget } from '../../../WidgetProvider';

const TitleWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface IProps {
	currentScreen: ScreenType;
	headerTextLangKey?: string,
	hideWidget: () => void,
	goOneScreenBack?: () => void,
	subtextKey: string,
	hideWorkingTime?: boolean,
}

const WidgetStartHeader = (props: IProps) => {
	const {
		currentScreen,
		headerTextLangKey,
		hideWidget,
		goOneScreenBack,
		subtextKey,
		hideWorkingTime,
	} = props;

	const getTranslate = useContext(LangContext);
	const { widgetSettings } = useWidget();
	const subtextKeyTranslate = getTranslate(subtextKey);

	const headerTextTranslate = getTranslate(headerTextLangKey || 'welcome');

	return (
		<StyledWidgetStartHeader>
			<TitleWithButton>
				<StyledWidgetTitle>
					{currentScreen !== 'chooseChannels' && goOneScreenBack && (
						<Icon
							name="arrowLeft"
							className="arrowLeft"
							onClick={goOneScreenBack}
						/>
					)}
					<div className="titleTexts">
						<Typography
							variant="h3"
						>
							{headerTextTranslate}
						</Typography>
						{subtextKey && (
							<Typography
								className="subtext"
								variant="button1"
							>
								{subtextKeyTranslate}
							</Typography>
						)}
					</div>
				</StyledWidgetTitle>
				<Icon
					onClick={hideWidget}
					name="chevronDownThin"
					className="closeIcon"
				/>
			</TitleWithButton>
			{widgetSettings.is_working_now || hideWorkingTime ? null : (
				<Schedule />
			)}
		</StyledWidgetStartHeader>
	);
};

export default WidgetStartHeader;