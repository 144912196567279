import styled from 'styled-components';

import { getCssFromTheme } from '../../../utils/css';
import { IStyledProps } from '../../../types';

export const StyledButton = styled.button<IStyledProps>`
  ${({ theme, variant }) => getCssFromTheme(theme, 'button', variant)};
  ${({ theme, typographyVariant }) => getCssFromTheme(theme, 'typography', typographyVariant)};
  
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || '12px 15px'};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;

  &.withoutBorder {
    border: none;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.small {
    min-height: 18px;
  }

  &.medium {
    min-height: 42px;
  }

  &:hover:not(.disabled) {
    opacity: 0.7;
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabled {
    ${({ theme }) => getCssFromTheme(theme, 'button', 'disabled')};
    border: none;
    cursor: not-allowed;

    .icon path {
      stroke: ${({ theme }) => theme.components.button.disabled.color};
    }
  }
`;