import { useEffect, useState } from 'react';
import { handleFirstMessage } from './liveChatDelayedFirstMessage';
import { IAutoReplySettings, MessageStatus } from '../types';
import { handleSecondMessage } from './liveChatDelayedSecondMessage';
import { idleOperatorNewChat } from './liveChatDelayedIdleOperatorNewChat';
import { idleOperatorOpenChat } from './liveChatDelayedIdleOperatorOpenChat';
import { idleGuest } from './liveChatDelayedIdleGuest';
import { useLiveChat } from '../LiveChatProvider';
import { SYSTEM_MESSAGES } from '../enums';
import { handleSystemMessagesWithoutTimeOut } from './systemMessagesWithoutTimeOut';
import { useWidget } from '../WidgetProvider';
import { isMessageSendedToday, today } from './helpers';

export const systemMessagesHandler = ({
	autoReplySettings,
	isNonWorkingTime,
} : {
	autoReplySettings: IAutoReplySettings,
	isNonWorkingTime: boolean,
}) => {
	const {
		history: chatHistory,
		chatStatus,
		eventJustSent,
		eventJustReceived,
		setSystemMessages,
		chatStarted,
		setHistory,
		latestHistoryEventType,
	} = useLiveChat();

	const {
		chatStartedOrigin,
		liveChatOpenedAfterFirstAutoReply,
		widgetSettings,
	} = useWidget();

	const [isFirstMessageProgress, setIsFirstMessageProgress] = useState<boolean | null>(null);
	const [timeoutIdFirstMessage, setTimeoutIdFirstMessage] = useState<null | NodeJS.Timeout>(null);
	const [timeoutIdSecondMessage, setTimeoutIdSecondMessage] = useState<null | NodeJS.Timeout>(null);
	const [timeoutIdIdleOperatorNewChat,
		setTimeoutIdIdleOperatorNewChat] = useState<null | NodeJS.Timeout>(null);
	const [timeoutIdIdleOperatorOpenChat,
		setTimeoutIdIdleOperatorOpenChat] = useState<null | NodeJS.Timeout>(null);
	const [timeoutIdIdleGuest,
		setTimeoutIdIdleGuest] = useState<null | NodeJS.Timeout>(null);
	const [isInstantFirstMessageProgress,
		setIsInstantFirstMessageProgress] = useState<boolean | null>(null);

	const { live_chat_delayed, all_channels_instant } = autoReplySettings;
	const {
		is_form_displayed,
	} = widgetSettings.settings;

	const {
		first_message: liveChatDelayedFirstMessage,
		second_message: liveChatDelayedSecondMessage,
		idle_operator_new_chat: liveChatDelayedIdleOperatorNewChat,
		idle_operator_open_chat: liveChatDelayedIdleOperatorOpenChat,
		idle_guest: liveChatDelayedIdleGuest,
	} = live_chat_delayed;

	const {
		first_message: allChannelsInstantFirstMessage,
		second_message: allChannelsInstantSecondMessage,
		non_working_hours: allChannelsInstantNonWorkingHours,
	} = all_channels_instant || {};

	useEffect(() => {
		const isNoFormDisplayedOrClosedChat = !is_form_displayed || (is_form_displayed && chatStatus === 'closed');

		if (!isNonWorkingTime) {
			// live_chat_delayed.first_message
			if (liveChatDelayedFirstMessage
				&& liveChatDelayedFirstMessage.status === MessageStatus.active
				&& isFirstMessageProgress === null
				&& isNoFormDisplayedOrClosedChat) {
				handleFirstMessage({
					message: liveChatDelayedFirstMessage,
					liveChatOpenedAfterFirstAutoReply,
					chatStatus,
					setIsFirstMessagesProgress: setIsFirstMessageProgress,
					setHistory,
					setSystemMessages,
					setTimeoutId: setTimeoutIdFirstMessage,
					chatStartedOrigin,
					chatHistory,
				});
			}

			// live_chat_delayed.second_message
			if (liveChatDelayedSecondMessage
				&& liveChatDelayedSecondMessage.status === MessageStatus.active
				&& isFirstMessageProgress === true
				&& isNoFormDisplayedOrClosedChat) {
				handleSecondMessage({
					message: liveChatDelayedSecondMessage,
					chatStarted,
					setHistory,
					setSystemMessages,
					setTimeoutId: setTimeoutIdSecondMessage,
					chatStartedOrigin,
				});
			}
		}
	}, [!!autoReplySettings, !!chatHistory, chatStarted, isNonWorkingTime,
		timeoutIdFirstMessage, isFirstMessageProgress, chatStatus]);

	useEffect(() => {
		if (!isNonWorkingTime) {
			// live_chat_delayed.idle_operator_new_chat
			if (liveChatDelayedIdleOperatorNewChat && liveChatDelayedIdleOperatorNewChat.status
				=== MessageStatus.active
				&& eventJustSent === 'contact_message' && chatStatus === 'new') {
				idleOperatorNewChat({
					message: liveChatDelayedIdleOperatorNewChat,
					eventJustReceived,
					setHistory,
					setSystemMessages,
					timeoutId: timeoutIdIdleOperatorNewChat,
					setTimeoutId: setTimeoutIdIdleOperatorNewChat,
				});
			}

			// live_chat_delayed.idle_operator_open_chat
			if (liveChatDelayedIdleOperatorOpenChat
				&& liveChatDelayedIdleOperatorOpenChat.status
				=== MessageStatus.active
				&& eventJustSent === 'contact_message' && chatStatus === 'open'
			) {
				idleOperatorOpenChat({
					message: liveChatDelayedIdleOperatorOpenChat,
					setHistory,
					setSystemMessages,
					timeoutId: timeoutIdIdleOperatorOpenChat,
					setTimeoutId: setTimeoutIdIdleOperatorOpenChat,
				});
			}

			// live_chat_delayed.idle_guest
			if (liveChatDelayedIdleGuest
				&& liveChatDelayedIdleGuest.status
				=== MessageStatus.active
				&& chatStatus === 'open'
				&& eventJustReceived === 'operator_message'
			) {
				idleGuest({
					message: liveChatDelayedIdleGuest,
					eventJustSent,
					setHistory,
					setSystemMessages,
					timeoutId: timeoutIdIdleGuest,
					setTimeoutId: setTimeoutIdIdleGuest,
					latestHistoryEventType,
				});
			}
		}
	}, [!!autoReplySettings, isNonWorkingTime, eventJustSent,
		eventJustReceived, chatStatus]);

	useEffect(() => {
		// all_channels_instant.first_message
		const isFirstMessageSendedToday = isMessageSendedToday(
			SYSTEM_MESSAGES['all_channels_instant.first_message'],
			chatStartedOrigin,
		);

		if (!isNonWorkingTime && allChannelsInstantFirstMessage && allChannelsInstantFirstMessage.status
			=== MessageStatus.active
			&& chatStatus === 'new' && isInstantFirstMessageProgress === null && !isFirstMessageSendedToday && eventJustSent === 'contact_message') {
			setIsInstantFirstMessageProgress(true);
			handleSystemMessagesWithoutTimeOut({
				message: allChannelsInstantFirstMessage,
				setHistory,
				setSystemMessages,
				chatStartedOrigin,
				type: SYSTEM_MESSAGES['all_channels_instant.first_message'],
				setIsMessageProgress: setIsInstantFirstMessageProgress,
			});
		}

		// all_channels_instant.second_message
		const isSecondMessageSendedToday = isMessageSendedToday(
			SYSTEM_MESSAGES['all_channels_instant.second_message'],
			chatStartedOrigin,
		);

		if (!isNonWorkingTime && allChannelsInstantSecondMessage
			&& allChannelsInstantSecondMessage.status
			=== MessageStatus.active
			&& chatStatus === 'new' && isInstantFirstMessageProgress === false && !isSecondMessageSendedToday) {
			handleSystemMessagesWithoutTimeOut({
				message: allChannelsInstantSecondMessage,
				setHistory,
				setSystemMessages,
				chatStartedOrigin,
				type: SYSTEM_MESSAGES['all_channels_instant.second_message'],
			});
		}

		// all_channels_instant.non_working_hours
		const isNonWorkingHoursMessageSendedToday = isMessageSendedToday(
			SYSTEM_MESSAGES['all_channels_instant.non_working_hours'],
			chatStartedOrigin,
		);
		if (isNonWorkingTime && allChannelsInstantNonWorkingHours && allChannelsInstantNonWorkingHours.status === MessageStatus.active && !isNonWorkingHoursMessageSendedToday && eventJustSent === 'contact_message') {
			handleSystemMessagesWithoutTimeOut({
				message: allChannelsInstantNonWorkingHours,
				setHistory,
				setSystemMessages,
				chatStartedOrigin,
				type: SYSTEM_MESSAGES['all_channels_instant.non_working_hours'],
			});
		}
	}, [!!autoReplySettings, isNonWorkingTime,
		chatStatus, isInstantFirstMessageProgress, eventJustSent]);

	useEffect(() => {
		const isSendedToday = isMessageSendedToday(SYSTEM_MESSAGES['live_chat_delayed.first_message'], chatStartedOrigin);

		if (chatStatus === 'closed' && isSendedToday) {
			setIsFirstMessageProgress(null);
			setIsInstantFirstMessageProgress(null);
			setSystemMessages([]);
		}

		if (chatStatus === 'open' && timeoutIdIdleOperatorNewChat) {
			clearTimeout(timeoutIdIdleOperatorNewChat);
			setTimeoutIdIdleOperatorNewChat(null);
		}

		if (chatStatus === 'new' || chatStatus === 'open') {
			localStorage.setItem(`${SYSTEM_MESSAGES['live_chat_delayed.first_message']}-${chatStartedOrigin}`, today);
			localStorage.setItem(`${SYSTEM_MESSAGES['live_chat_delayed.second_message']}-${chatStartedOrigin}`, today);
		}

		if ((chatStatus === 'new' || chatStatus === 'open') && timeoutIdFirstMessage) {
			clearTimeout(timeoutIdFirstMessage);
			setIsFirstMessageProgress(null);
		}

		if ((chatStatus === 'new' || chatStatus === 'open') && timeoutIdSecondMessage) {
			clearTimeout(timeoutIdSecondMessage);
		}
	}, [chatStatus]);

	useEffect(() => {
		if (timeoutIdFirstMessage
			&& (eventJustReceived === 'chat_closed'
			|| eventJustReceived === 'operator_joined'
			|| eventJustReceived === 'operator_message'
			|| eventJustSent === 'contact_message'
			|| eventJustSent === 'chat_closed')
		) {
			clearTimeout(timeoutIdFirstMessage);
			setTimeoutIdFirstMessage(null);
		}

		if (timeoutIdSecondMessage
				&& (eventJustReceived === 'chat_closed'
				|| eventJustReceived === 'operator_joined'
				|| eventJustReceived === 'operator_message'
				|| eventJustSent === 'contact_message'
				|| eventJustSent === 'chat_closed')
		) {
			clearTimeout(timeoutIdSecondMessage);
			setTimeoutIdSecondMessage(null);
		}

		if (timeoutIdIdleOperatorOpenChat
			&& (eventJustReceived === 'chat_closed'
			|| eventJustSent === 'chat_closed'
			|| eventJustReceived === 'operator_message')
		) {
			clearTimeout(timeoutIdIdleOperatorOpenChat);
			setTimeoutIdIdleOperatorOpenChat(null);
		}

		if (timeoutIdIdleGuest
			&& (eventJustReceived === 'chat_closed'
			|| eventJustSent === 'chat_closed'
			|| eventJustSent === 'contact_message')
		) {
			setTimeoutIdIdleGuest(null);
			clearTimeout(timeoutIdIdleGuest);
		}
	}, [eventJustReceived, eventJustSent]);

	useEffect(() => {
		const isNonWorkingHoursMessageSendedToday = isMessageSendedToday(
			SYSTEM_MESSAGES['all_channels_instant.non_working_hours'],
			chatStartedOrigin,
		);
		const isSendedFirstMessageDelayedToday = isMessageSendedToday(SYSTEM_MESSAGES['live_chat_delayed.first_message'], chatStartedOrigin);
		if (!isNonWorkingTime && isNonWorkingHoursMessageSendedToday) {
			localStorage.removeItem(`${SYSTEM_MESSAGES['all_channels_instant.non_working_hours']}-${chatStartedOrigin}`);
		}
		if (isNonWorkingTime && timeoutIdFirstMessage) {
			clearTimeout(timeoutIdFirstMessage);
		}
		if (isNonWorkingTime && isSendedFirstMessageDelayedToday) {
			setSystemMessages([]);
			setHistory([]);
		}
	}, [isNonWorkingTime]);
};