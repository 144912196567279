import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from 'styled-components';

import {
	StyledButtonsRow, StyledErrorLabel, StyledInput, StyledLabel, StyledWrapper,
} from './styled';
import { isObjectEmpty } from '../../../utils';
import Icon from '../Icon';
import { IProps as IIconProps } from '../Icon/Icon';
import {
	ChatEvent, IFileUploadResult, NameTypeIcon, TextAreaRef,
} from '../../../types';
import { LangContext } from '../../../LangProvider';
import { FILE_TYPES } from '../../../constants';

interface IProps {
	children?: React.ReactNode,
	id?: string,
	rows?: number,
	className?: string,
	disabled?: boolean,
	error?: string | boolean,
	field?: {
		isUppercase: boolean,
	},
	fullWidth?: boolean,
	iconLeftProps?: IIconProps,
	iconRightProps?: IIconProps,
	insideButtons?: React.ReactNode,
	operatorName?: string,
	replyData?: {
		replyId: number | undefined,
		replyMsg: string,
		replyType: ChatEvent | null,
		replyFile?: IFileUploadResult | null
	}
	isOneNumberInput?: boolean,
	label?: string,
	labelType?: 'top' | 'border',
	multiline?: boolean,
	name?: string,
	onBlur?: () => void,
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
	onEnter?: (val: string) => void,
	onSubmit?: (val: string) => void,
	onClearReply?: () => void,
	placeholder?: string,
	placeholderColor?: string,
	required?: boolean,
	submitByEnterPressed?: boolean,
	success?: boolean,
	type?: 'text' | 'password' | 'number' | 'richText',
	value: string,
	withDebounce?: boolean,
	withQuantityButtons?: boolean,
	textAreaRef?: TextAreaRef,
	withoutBorder?: boolean,
	withoutValidation?: boolean,
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void,
	onPaste?: (e: React.ClipboardEvent<HTMLElement>) => void,
}

const getClassesWrapper = (props: IProps) => {
	const {
		className,
		disabled,
		error,
		insideButtons,
		success,
		withoutBorder,
		withoutValidation,
	} = props;

	return clsx(
		'inputWrapper',
		disabled && 'disabled',
		!!error && 'hasError',
		success && 'success',
		className,
		withoutValidation && 'withoutValidation',
		insideButtons && 'withInsideButtons',
		withoutBorder && 'withoutBorder',
	);
};

const getClassesInput = (props: IProps) => {
	const {
		error,
		iconLeftProps,
		iconRightProps,
		isOneNumberInput,
		multiline,
		success,
		withQuantityButtons,
	} = props;

	const hasLeftIcon = !isObjectEmpty(iconLeftProps);
	const hasRightIcon = !isObjectEmpty(iconRightProps);

	return clsx(
		!!error && 'hasError',
		success && 'success',
		hasLeftIcon && 'hasLeftIcon',
		hasRightIcon && 'hasRightIcon',
		multiline && 'multiline',
		withQuantityButtons && 'withQuantityButtons',
		isOneNumberInput && 'isOneNumberInput',
	);
};

const Input = (props: IProps) => {
	const {
		id,
		rows,
		children,
		disabled,
		error,
		field,
		textAreaRef,
		fullWidth,
		iconLeftProps,
		iconRightProps,
		insideButtons,
		operatorName,
		replyData,
		onKeyDown,
		label,
		labelType,
		multiline,
		name,
		onBlur,
		onChange,
		onEnter,
		onSubmit,
		onClearReply,
		placeholder,
		placeholderColor,
		required,
		submitByEnterPressed = true,
		type,
		value,
		withDebounce,
		onPaste,
	} = props;

	const {
		replyId, replyMsg, replyType, replyFile,
	} = replyData ?? {};

	const theme = useTheme();
	const getTranslate = useContext(LangContext);
	const contactName = getTranslate('you');

	useEffect(() => {
		if (replyId) {
			textAreaRef.current.focus();
		}
	}, [replyId]);

	const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange && !withDebounce) {
			onChange(e);
		}
	};

	const handleKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
		if (onKeyDown) {
			onKeyDown(e);
		} else if (!disabled && submitByEnterPressed && (onEnter || onSubmit) && e.key === 'Enter') {
			if (onSubmit) {
				// @ts-ignore
				onSubmit(e.target.value);
			}
			if (onEnter) {
				// @ts-ignore
				onEnter(e.target.value);
			}
		}
	};

	const getLeftFillColor = () => {
		if (disabled) return theme.color.general.lighter;
		if (error) return theme.color.status.error;
		return theme.color.general.light;
	};

	const getRightFillColor = () => {
		if (disabled) return theme.color.general.lighter;
		if (error) return theme.color.status.error;
		return theme.color.general.black;
	};

	const getReplyType = () => {
		if (replyType) {
			return replyType?.split('_')[0];
		}
		return '';
	};

	const getReplyIconColor = () => (replyType === 'contact_message'
		? theme.color.secondary.darker
		: theme.color.general.orange);

	const getReplyName = () => (replyType === 'contact_message' ? contactName : operatorName);

	const getIconTypeFile = (file: IFileUploadResult) => {
		const fileType = file.file_name?.split('.').at(-1);
		return `type${fileType?.toUpperCase()}` as NameTypeIcon;
	};

	const getReplyData = () => {
		const isImageFileData = replyFile && Object.keys(FILE_TYPES.images).includes(replyFile?.type);

		const	isFileFileData = replyFile && Object.keys(FILE_TYPES.files).includes(replyFile?.type);

		const isFileContent = !replyMsg && replyFile ? 'file' : '';

		return (
			<div className={`${getReplyType()}-msg replyContainer`}>
				<Icon name="reply" width={16} height={16} fill={getReplyIconColor()} className="replyIcon" />
				<div className={`${isFileContent} replyContent`}>
					{ !replyMsg && replyFile && isImageFileData
							&& <img src={replyFile.url} alt={replyFile.file_name} />}
					{
						!replyMsg && replyFile && isFileFileData
							&& <Icon name={getIconTypeFile(replyFile)} className="iconType" />
					}
					<div>
						<div className="replyName">
							{getReplyName()}
						</div>
						{!replyMsg && replyFile && <div className="fileName">{replyFile.file_name}</div>}
					</div>
					<div className="replyMsg">
						{replyMsg}
					</div>
				</div>
				<Icon onClick={onClearReply} name="cross" className="closeIcon" fill={theme.color.general.light} />
			</div>
		);
	};

	return (
		<>
			<StyledWrapper
				fullWidth={fullWidth}
				className={getClassesWrapper(props)}
			>
				{label && labelType === 'border' && (
					<StyledLabel
						className={clsx(required && 'required', 'label', 'border')}
						isUppercase={field?.isUppercase}
					>
						{label?.toLowerCase()}
					</StyledLabel>
				)}

				{!!iconLeftProps && (
					<Icon
						width={16}
						height={16}
						className="leftIcon"
						fill={getLeftFillColor()}
						/* eslint-disable-next-line react/jsx-props-no-spreading */
						{...iconLeftProps}
					/>
				)}
				{replyId && (replyMsg || replyFile) && getReplyData()}
				<StyledInput
					onKeyDown={handleKeypress}
					as={(multiline && 'textarea') || ''}
					name={name}
					disabled={disabled}
					value={value}
					onBlur={onBlur}
					onChange={onChangeState}
					onPaste={onPaste}
					placeholder={placeholder}
					placeholderColor={placeholderColor}
					type={type}
					className={getClassesInput(props)}
					id={id}
					rows={rows}
					ref={textAreaRef}
				/>
				{insideButtons && <StyledButtonsRow className="buttonsRow">{insideButtons}</StyledButtonsRow>}

				{!!iconRightProps && (
					<Icon
						size={15}
						className="rightIcon"
						fill={getRightFillColor()}
						/* eslint-disable-next-line react/jsx-props-no-spreading */
						{...iconRightProps}
					/>
				)}

				{children}
			</StyledWrapper>
			{!!error && typeof error === 'string' && (
				<StyledErrorLabel icon={<Icon name="inputError" size={13} />} className="error">
					{error}
				</StyledErrorLabel>
			)}
		</>
	);
};

Input.defaultProps = {
	error: '',
	isOneNumberInput: true,
	labelType: 'border',
	type: 'text',
	withQuantityButtons: false,
};

export default Input;