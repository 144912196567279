import { IChatEventBrief, MessageStatus } from '../types';
import { constructMessageData, today } from './helpers';

interface Params {
	message: {
    text: string,
    status: MessageStatus
	},
	setHistory: React.Dispatch<React.SetStateAction<IChatEventBrief[]>>,
	setSystemMessages: React.Dispatch<React.SetStateAction<IChatEventBrief[] | []>>,
	chatStartedOrigin: string,
	type: string,
	setIsMessageProgress?: React.Dispatch<React.SetStateAction<boolean | null>>,
}

export const handleSystemMessagesWithoutTimeOut = (props: Params) => {
	const {
		message,
		setHistory,
		setSystemMessages,
		chatStartedOrigin,
		type,
		setIsMessageProgress,
	} = props;
	const systemMessages = constructMessageData(message) as IChatEventBrief;
	if (systemMessages) {
		setHistory((prev: IChatEventBrief[]) => [...prev, systemMessages]);
		setSystemMessages((prev) => [...prev, systemMessages]);

		if (setIsMessageProgress) {
			setIsMessageProgress(false);
		}

		if (type) {
			localStorage.setItem(`${type}-${chatStartedOrigin}`, today);
		}
	}
};