import { IChatEventBrief, MessageStatus } from '../types';
import { constructMessageData } from './helpers';

interface Params {
	message: {
    text: string,
    delay: number,
    status: MessageStatus
	},
	eventJustSent: string,
	setHistory: React.Dispatch<React.SetStateAction<IChatEventBrief[]>>,
	setSystemMessages: React.Dispatch<React.SetStateAction<IChatEventBrief[] | []>>,
	timeoutId: null | NodeJS.Timeout,
	setTimeoutId: React.Dispatch<React.SetStateAction<null | NodeJS.Timeout>>,
	latestHistoryEventType: string | undefined,
}

export const idleGuest = (props: Params) => {
	const {
		message,
		eventJustSent,
		setHistory,
		setSystemMessages,
		timeoutId,
		setTimeoutId,
		latestHistoryEventType,
	} = props;
	const idleGuestMessage: IChatEventBrief = constructMessageData(message) as IChatEventBrief;

	const onSetTimeout = () => {
		const id = setTimeout(() => {
			setHistory((prev: IChatEventBrief[]) => [...prev, idleGuestMessage]);

			setSystemMessages((prev) => [...prev, idleGuestMessage]);
		}, message.delay * 1000);
		setTimeoutId(id);
	};

	const onUpdateTimeout = (timeoutId: NodeJS.Timeout) => {
		clearTimeout(timeoutId);
		onSetTimeout();
	};

	const isLatestHistoryEventTypeOperator = latestHistoryEventType === 'operator_message';

	if (!timeoutId && eventJustSent !== 'contact_message' && isLatestHistoryEventTypeOperator) {
		onSetTimeout();
	}

	if (timeoutId && isLatestHistoryEventTypeOperator) {
		onUpdateTimeout(timeoutId);
	}
};