import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useLiveChat } from '../LiveChatProvider';
import { getFileUrl, mapEventWithFiles } from '../api';
import { FILE_TYPES } from '../constants';
import { transformDateWithDay } from '../utils';
import FilesList from './FilesList';
import FlexRow from './atomic/FlexRow';
import Icon from './atomic/Icon';
import TextPanel from './atomic/TextPanel';
import Typography from './atomic/Typography';
import Userpic from './atomic/Userpic';
import { StyledChatMessage } from './styled';
import {
	ChatEvent,
	IChatEventBrief,
	IFileUploadResult,
	NameTypeIcon,
} from '../types';
import { useWidget } from '../WidgetProvider';
import { prepareMessageText } from '../utils/utils';
import { LangContext } from '../LangProvider';

interface IProps {
	action: IChatEventBrief,
	isSystemMessage: boolean,
	operatorOnline: boolean,
	onScrollReplyMsg: (id: number) => void,
	onClickReply: (
		id: number,
		replyMsg: string,
		type: ChatEvent,
		file: IFileUploadResult | null,
		owner?: string,
	) => void,
}

const getFileTypeFromName = (name?: string) => name?.split('.')?.pop()?.toLowerCase();

const getFiles = (files: IFileUploadResult[], types: Record<string, string[]>) => {
	if (!files || !Array.isArray(files)) {
		return undefined;
	}

	return files?.filter((file) => (file.type
		? Object.keys(types).includes(file.type)
		: Object.values(types)
			.flat()
			.includes(`.${getFileTypeFromName(file.file_name)}`)));
};

const MessageItem = (props: IProps) => {
	const {
		action,
		isSystemMessage,
		operatorOnline,
		onScrollReplyMsg,
		onClickReply,
	} = props;
	const {
		at,
		operator_name,
		operator_pic: operatorPicProp,
		data: {
			text,
			files,
			channel_message_id: channelMessageId,
			reply_message_id: replyMessageId,
			reply_message_text: replyMessageText,
			reply_message_file_data: replyFileData,
			reply_message_type: replyMessageType,
			business_images: businessImages,
		},
		type,
	} = action;

	const { chatStatus, history } = useLiveChat();

	const operatorPicFromHistory = [...history].reverse().find((i) => i.operator_pic)?.operator_pic;
	const operator_pic = operatorPicProp || operatorPicFromHistory;

	const theme = useTheme();
	const isMessageFromOperator = type === 'operator_message';
	const showBotAvatar = isSystemMessage && chatStatus !== 'open';

	const messageFiles = files && getFiles(files, FILE_TYPES.files);
	const messageImages = files && getFiles(files, FILE_TYPES.images);

	const { setHistory } = useLiveChat();

	const { widgetSettings } = useWidget();
	const { language } = widgetSettings;

	const getTranslate = useContext(LangContext);
	const contactName = getTranslate('you');

	const handleFileImg = (res: IChatEventBrief | null) => {
		setHistory((prev) => (
			prev.map((item) => ((item?.id === action?.id && res) ? res : item))
		));
	};

	useEffect(() => {
		const filesWithImg = files?.filter((file) => file?.file_code && !file?.url);

		if (filesWithImg?.length) {
			mapEventWithFiles(filesWithImg, action)
				// @ts-ignore
				.then(handleFileImg);
		}
	}, [files, setHistory, action]);

	const handleFileUrl = (res: IChatEventBrief) => {
		setHistory((prev) => prev.map((item) => (
			operator_pic === item.operator_pic
				? { ...item, operator_pic: res.operator_pic }
				: item
		)));
	};

	useEffect(() => {
		if (operator_pic && !operator_pic?.startsWith('http')) {
			getFileUrl(null, 'operator_pic', operator_pic)
				.then(handleFileUrl);
		}
	}, [operator_pic, setHistory]);

	const getTitle = () => (
		<Typography
			variant="body2"
			text={at ? transformDateWithDay(at, language) : ''}
			color={theme.color.general.dark}
			fontSize="13px"
		/>
	);

	const getIconTypeFile = (file: IFileUploadResult) => {
		const fileType = file.file_name?.split('.').at(-1);
		return `type${fileType?.toUpperCase()}` as NameTypeIcon;
	};

	const getFileDataContent = (replyFileData: IFileUploadResult) => {
		const isImageFileData = Object.keys(FILE_TYPES.images).includes(replyFileData?.type);

		const isFileFileData = Object.keys(FILE_TYPES.files).includes(replyFileData?.type);

		return (
			<div className="replyFileDataContent">
				{ isImageFileData && <img src={replyFileData.url} alt={replyFileData.file_name} className="replyImg" /> }
				{ isFileFileData && <Icon name={getIconTypeFile(replyFileData)} className="iconType" />}
				<div>
					<div className="contactName">
						{ replyMessageType === 'contact_message'
							? contactName : operator_name}
					</div>
					<div className="fileName">{replyFileData.file_name}</div>
				</div>
			</div>
		);
	};

	const onClickReplyBtn = () => {
		const file = files?.length ? files[0] : null;
		if (type) {
			onClickReply(
				channelMessageId!,
				text || '',
				type,
				file,
				replyMessageType,
			);
		}
	};

	const messageFrom = isMessageFromOperator || isSystemMessage ? 'operatorMessage' : 'contactMessage';

	const messageOwner = replyMessageType?.split('_')[0];

	return (
		<StyledChatMessage
			className={clsx(messageFrom)}
		>
			<FlexRow alignItems="start" className={clsx(text && 'withText', 'messageRow')}>
				{!showBotAvatar && (isMessageFromOperator || isSystemMessage) && operator_pic && (
					<Userpic height={36} width={36} src={operator_pic} operatorOnline={operatorOnline} />
				)}
				{showBotAvatar && <Icon margin="0 10px 0 0" name="bot" />}
				<TextPanel
					title={getTitle()}
					content={(
						<>
							{channelMessageId && !isSystemMessage && (
								<button
									onClick={onClickReplyBtn}
									className="replyBtn"
									type="button"
									aria-label="reply"
								>
									<span><Icon name="reply" width={20} height={20} /></span>
								</button>
							)}
							{replyMessageId && replyMessageText && (
								<div className={`${messageOwner} withReply`} onClick={() => onScrollReplyMsg(replyMessageId)}>
									<div className="contactName">
										{ replyMessageType === 'contact_message' ?	contactName : operator_name}
									</div>
									{/* eslint-disable-next-line react/no-danger */}
									<div dangerouslySetInnerHTML={{ __html: prepareMessageText(replyMessageText || '') }} className="withReplyText" />
								</div>
							)}
							{replyMessageId && !replyMessageText?.length && replyFileData && (
								<div className={`${messageOwner} withReply`} onClick={() => onScrollReplyMsg(replyMessageId)}>
									{getFileDataContent(replyFileData[0])}
								</div>
							)}
							{/* eslint-disable-next-line react/no-danger */}
							{text && <div dangerouslySetInnerHTML={{ __html: prepareMessageText(text) }} />}
							{!!businessImages?.length && (
								<FilesList
									// @ts-ignore
									files={businessImages.map((url) => ({ url }))}
									type="images"
									preventDownload
								/>
							)}
							{!!messageImages?.length && <FilesList files={messageImages} type="images" />}
							{!!messageFiles?.length && <FilesList files={messageFiles} type="files" />}
						</>
					)}
					className="chatMessage"
					padding="0"
				/>
			</FlexRow>
		</StyledChatMessage>
	);
};

export default MessageItem;