import { CHAT_EVENTS_LOAD_LIMIT } from './constants';
import {
	ICallbackData,
	IChatEventBrief,
	IEvent,
	IFileUploadResult,
	IKnowledgeBaseArticleDetailsResponse,
	IKnowledgeBaseResponse,
	IResponseChatContactEvents,
	IResponseChatContactUpload,
	IResponseChatFileUrl,
	IResponseChatWidgetLicense,
} from './types';

const API_URL_START = process.env.REACT_APP_API_HOST || 'http://localhost:8000';

export const getWidgetSettings = async (licenceId: string) => {
	try {
		const response = await fetch(`${API_URL_START}/chat/widget/${licenceId}`);

		const data: IResponseChatWidgetLicense = await response.json();

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getChatHistory = async (license: string, contactUid: string, offset?: number) => {
	try {
		const params = new URLSearchParams({
			contact_uid: contactUid,
			license_id: license,
			channel: 'live_chat',
			sort: 'id_E_desc',
			limit: CHAT_EVENTS_LOAD_LIMIT.toString(),
			offset: (offset || 0).toString(),
		});

		const response = await fetch(`${API_URL_START}/chat/contact_events?${params}`);

		const data: IResponseChatContactEvents = await response.json();

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getFileUrl = async (
	file: IFileUploadResult | null,
	imgKey?: string,
	fileCode?: string,
) => {
	try {
		const response = await fetch(
			`${API_URL_START}/chat/file_url?file_code=${fileCode || file?.file_code}`,
		);
		const data: IResponseChatFileUrl = await response.json();

		return { ...file, [imgKey || 'url']: data.url } as IChatEventBrief;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const mapEventWithFiles = async (files: IFileUploadResult[], event: IEvent) => {
	try {
		const requests = await files.map(async (file) => getFileUrl(file));

		return await Promise.all(requests)
			.then((responses) => ({ ...event, data: { ...event.data, files: responses } }))
			.catch(() => null);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const uploadFile = async (license: string, contactUid: string, file: Blob) => {
	try {
		const fd = new FormData();
		fd.append('file_obj', file);

		const response = await fetch(
			`${API_URL_START}/chat/contact/upload?contact_uid=${contactUid}&license_id=${license}&channel=live_chat`,
			{
				method: 'POST',
				body: fd,
			},
		);

		const data: IResponseChatContactUpload = await response.json();

		const response1 = await fetch(`${API_URL_START}/chat/file_url?file_code=${data.file_code}`);

		const { url } = await response1.json();

		return ({ ...data, type: file.type, url }) as IFileUploadResult;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getOperatorOnlineStatus = async (licenseId: string, contactUid: string) => {
	try {
		const response = await fetch(
			`${API_URL_START}/chat/operators_online?license_id=${licenseId}&contact_uid=${contactUid}`,
		);

		const data: string = await response.json();

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendCallbackData = async (
	licenseId: string,
	contactUid: string,
	data: ICallbackData,
) => {
	try {
		const response = await fetch(
			`${API_URL_START}/chat/callback_request?contact_uid=${contactUid}&license_id=${licenseId}`,
			{
				method: 'POST',
				body: JSON.stringify({ ...data }),
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);

		return await response.json();
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getKnowledgeBaseArticleList = async (licenseId: string) => {
	try {
		const params = new URLSearchParams({
			license_id: licenseId,
		});

		const response = await fetch(
			`${API_URL_START}/manager/knowledge-base/widget/articles?${params}`,
		);

		const data: IKnowledgeBaseResponse = await response.json();

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getKnowledgeBaseArticleSearchList = async (licenseId: string, query: string) => {
	try {
		const params = new URLSearchParams({
			license_id: licenseId,
			query,
		});

		const response = await fetch(
			`${API_URL_START}/manager/knowledge-base/widget/articles/search?${params}`,
		);

		const data: IKnowledgeBaseResponse = await response.json();

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getKnowledgeBaseArticle = async (id: number) => {
	try {
		const response = await fetch(
			`${API_URL_START}/manager/knowledge-base/widget/articles/${id}`,
		);

		const data: IKnowledgeBaseArticleDetailsResponse = await response.json();

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

type InteractType = 'like' | 'dislike' | 'view' | 'read' | 'time_spent';

export const sendKnowledgeBaseArticleInteract = async (
	id: number,
	type: InteractType,
	amount?: number,
) => {
	try {
		const params = new URLSearchParams({
			interaction_type: type,
		});

		if (amount) {
			params.append('amount', amount.toString());
		}

		await fetch(
			`${API_URL_START}/manager/knowledge-base/widget/articles/${id}/interact?${params}`,
			{
				method: 'POST',
			},
		);
		return null;
	} catch (error) {
		return Promise.reject(error);
	}
};