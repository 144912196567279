import styled from 'styled-components';
import { IStyledProps } from '../../../types';

const getSuffix = (item: string | number| undefined) => (
	typeof item === 'number' ? 'px' : ''
);

export const StyledImage = styled.figure<IStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  margin: ${({ margin }) => margin || 0};

  img {
    width: ${({ width }) => (width ? `${width}${getSuffix(width)}` : 'auto')};
    max-width: ${({ maxWidth = '100%' }) => `${maxWidth}${getSuffix(maxWidth)}`};
    height: ${({ height }) => (height ? `${height}${getSuffix(height)}` : 'auto')};
    max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}${getSuffix(maxHeight)}`};
    border-radius: ${({ radius }) => `${radius || 0}${getSuffix(radius)}`};
    object-fit: cover;
  }
`;

export const StyledActions = styled.div<IStyledProps>`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px;
  border-radius: ${({ radius }) => `${radius || 0}${getSuffix(radius)}`};
  transition: 0.5s ease-out;

  &.onHover {
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: ${({ theme }) => theme.color.general.lightest}66;
    opacity: 0;
  }

  .icon {
    cursor: pointer;
  }
`;

export const StyledImageAsBackground = styled.div<IStyledProps>`
  width: ${({ width }) => (width ? `${width}${getSuffix(width)}` : 'auto')};
  max-width: ${({ maxWidth }) => `${maxWidth}${getSuffix(maxWidth)}`};
  height: ${({ height }) => (height ? `${height}${getSuffix(height)}` : 'auto')};
  max-height: ${({ maxHeight }) => `${maxHeight}${getSuffix(maxHeight)}`};
  margin: ${({ margin }) => margin};
  border-radius: ${({ radius }) => `${radius || 0}${getSuffix(radius)}`};
  background: url(${({ src }) => src}) no-repeat center;
  background-size: ${({ backgroundSize }) => backgroundSize};
`;

export const StyledImageWithActionsWrapper = styled.div<IStyledProps>`
  position: relative;
  border-radius: ${({ radius }) => `${radius || 0}${getSuffix(radius)}`};

  &:hover ${StyledActions} {
    opacity: 1;
  }
`;