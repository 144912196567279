import React, {
	Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { useTheme } from 'styled-components';

import Textarea from '../../components/Textarea';
import ChatArea from '../../components/ChatArea';
import FlexRow from '../../components/atomic/FlexRow';
import Icon from '../../components/atomic/Icon';
import Typography from '../../components/atomic/Typography';
import { useLiveChat } from '../../LiveChatProvider';
import { StyledChatsContainer } from './styled';
import WidgetHeader from '../../components/WidgetHeader';
import { StyledScreenWrapper } from '../WidgetButtonContainer/styled';
import WidgetFooter from '../ChooseChannels/components/WidgetFooter';
import { useWidget } from '../../WidgetProvider';
import {
	ChatEvent, IFileUploadResult, ScreenType,
} from '../../types';
import { LangContext } from '../../LangProvider';

interface IProps {
	className: string,
	contactUid: string,
	currentScreen: ScreenType,
	is_hide_branding: boolean,
	goOneScreenBack?: () => void,
	license: string,
	offlineText: string | false,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>,
}

const LiveChatContainer = (props: IProps) => {
	const {
		className,
		contactUid,
		currentScreen,
		is_hide_branding,
		goOneScreenBack,
		setCurrentScreen,
		license,
		offlineText,
	} = props;

	const theme = useTheme();

	const {
		history: chatHistory,
		operatorName,
		operatorOnline,
		chatStatus,
	} = useLiveChat();
	const { chatDetails } = useWidget();

	const getTranslate = useContext(LangContext);
	const operatorTranslate = getTranslate('operatorOffline', { operatorName });

	const [replyId, setReplyId] = useState<number| undefined>();
	const [replyMsg, setReplyMsg] = useState<string>('');
	const [replyType, setReplyType] = useState<ChatEvent | null>(null);
	const [replyFile, setReplyFile] = useState<IFileUploadResult | null>(null);

	const messageText = chatDetails?.text;

	const onClickReply = (
		id: number | undefined,
		replyMsg: string,
		type: ChatEvent,
		file: IFileUploadResult | null,
	) => {
		setReplyId(id);
		setReplyMsg(replyMsg);
		setReplyType(type);
		setReplyFile(file);
	};

	const onClearReply = () => {
		setReplyId(undefined);
		setReplyMsg('');
		setReplyType(null);
		setReplyFile(null);
	};

	return (
		<StyledScreenWrapper className={className}>
			<WidgetHeader
				goOneScreenBack={goOneScreenBack}
				offlineText={offlineText}
				setCurrentScreen={setCurrentScreen}
			/>
			<ChatArea
				chatHistory={chatHistory}
				operatorOnline={operatorOnline}
				license={license}
				contactUid={contactUid}
				currentScreen={currentScreen}
				onClickReply={onClickReply}
			/>

			{operatorName && chatStatus === 'open' && !operatorOnline && (
				<FlexRow
					justifyContent="center"
					className="offlineText"
				>
					<Icon
						margin="0 3px 0 0"
						height={12}
						width={14}
						name="offline"
					/>
					<Typography
						color={theme.color.general.dark}
						variant="input"
						textAlign="center"
					>
						{operatorTranslate}
					</Typography>
				</FlexRow>
			)}

			<StyledChatsContainer className="textarea">
				<Textarea
					currentScreen={currentScreen}
					messageText={messageText ?? ''}
					operatorName={operatorName}
					onClearReply={onClearReply}
					replyData={{
						replyId, replyMsg, replyType, replyFile,
					}}
				/>
			</StyledChatsContainer>
			{!is_hide_branding && <WidgetFooter />}
		</StyledScreenWrapper>
	);
};

export default LiveChatContainer;